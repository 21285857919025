import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import AppPageContainer from '../../components/AppPageContainer';
import ToolBar from '../../components/ToolBar';
import { Auth } from '../../modules/auth_module';
import { Keys } from '../../modules/keys_module';
import { Org } from '../../modules/organizations_module';

const DashboardPage = () => {
    const auth = Auth.use()
    const keys = Keys.use()
    const org = Org.use()

    useEffect(() => {
        if (!document.cookie.includes('kodama')) {
            navigate('/app/login');
        }
    }, [])

    return (
        <AppPageContainer activePage="api_keys">
            { auth.loggedIn && (
                <>
                    <ToolBar pageName="Your API Keys" />
                    {keys.keys?.some(x => !x.Enabled) && (
                        <div className="mt-4 p-3 border-error border rounded-lg bg-error bg-opacity-20">
                            Your Production API key is disabled until an admin confirms their email and add a payment method.
                        </div>
                    )}
                    <div className="p-3 mt-4">
                        <h1 className="text-2xl font-bold">Using your API Keys</h1>
                        <p className="mt-2">To use your API keys, set the <code className="bg-gray-200 p-1">x-api-key</code> header to your key. The test environment key will allow you to test quotes and purchasing, but will not provide accurate results. The production API keys will be accurate and charge your account for offset purchases.</p>
                        <p className="my-2">In order to use your production keys, your account must be confirmed and you must add a payment method.</p>
                    </div>
                    <div className="mt-4 p-3 border border-gray-200 rounded-lg w-full shadow-md">
                        <h2 className="text-xl font-bold">{org.org?.Name} API Keys</h2>
                        { keys.keys != null && keys.keys.map(key => (
                            <div key={key.Key} className="py-4 border-b border-gray-300 grid grid-cols-6">
                                <div className="flex col-span-2">
                                    <div className="bg-primary rounded-sm px-3 text-white mr-4">{key.Env} Key</div>
                                    {!key.Enabled && <div className="rounded-sm bg-error px-3 text-white mr-4">DISABLED</div> }
                                </div>
                                <div>
                                    <code className="bg-gray-200 p-2">{key.Key}</code>
                                    {/* <button className="ml-2 py-1 px-2 rounded-sm text-white bg-primary">Copy</button> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </AppPageContainer>
    );
};

export default DashboardPage;
